@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400;500;600;700;800&display=swap");
/* Constant styles  */
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: 16px;
  transition: all 0.4s ease-in;
  font-family: "Baloo Bhaijaan 2", cursive;
  direction: rtl;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  line-height: normal !important;
  font-family: "Baloo Bhaijaan 2", cursive;
  direction: rtl;
}

.css-67l5gl {
  background-color: transparent;
  box-shadow: none !important;
}

.exportcsv {
  background-color: #3259a8;
  color: #fff;
  border: 0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

a,
button {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* // Constant Styles */
ul {
  margin-bottom: 0;
}

.form-control {
  border-radius: 7px;
  background-color: rgba(245, 247, 250, 0.386);
}
.form-control:focus {
  outline: 0 !important;
  box-shadow: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

p {
  font-size: 1.1rem;
  color: #000;
}

/* Main Style */
.btn-style {
  background-color: #fedd00;
  padding: 14px 50px;
  color: #fff;
  font-size: 1.1rem;
  display: inline-block;
  text-align: center;
  border: 1px solid #fedd00;
  border-radius: 28px;
  text-align: center;
  max-width: 100%;
}
.btn-style:hover {
  color: #fedd00;
  background-color: #fff;
  border-color: #fedd00;
}

.card-img {
  height: 100vh;
  position: relative;
}
.card-img .img-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card-img .img-parent img,
.card-img .img-parent video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.algin-center {
  align-items: center;
}

.noauth {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.noauth img {
  width: 400px;
  margin: auto;
}
.noauth h5 {
  font-weight: bold;
  font-size: 1.8rem;
}

canvas {
  font-family: "Baloo Bhaijaan 2", cursive !important;
}

/*  */
.top_header {
  padding: 20px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
}
.top_header .form-control {
  width: 100%;
  border: 0;
  background-color: #f3f3f3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  height: 40px;
}
.top_header .search_input .form-group {
  position: relative;
}
.top_header .search_input .form-group i {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
}
.top_header .search_input .form-group .form-control {
  padding-right: 40px;
}
.top_header .notifications {
  text-align: left;
}
.top_header .notifications button {
  background-color: transparent;
  border: 0;
  position: relative;
}
.top_header .notifications button i {
  color: #3259a8;
  font-size: 1.5rem;
}
.top_header .notifications button:focus {
  box-shadow: none;
  outline: none;
}
.top_header .notifications button .notification_number {
  position: absolute;
  top: -6px;
  left: -5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}

.notifications {
  position: relative;
}
.notifications .notification_component {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
  z-index: 4;
  border-radius: 10px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
  width: -moz-max-content;
  width: max-content;
}
.notifications .notification_component h5 {
  text-align: right;
  background-color: #3259a8;
  padding: 15px;
  color: #fff;
  font-size: 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.notifications .notification_component .norilist {
  padding: 0;
  text-align: right;
  list-style: none;
  padding: 15px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 500px;
  overflow: auto;
}
.notifications .notification_component .norilist li {
  position: relative;
  border-bottom: 1px solid #eee;
  padding: 10px 15px 10px 0;
}
.notifications .notification_component .norilist li p {
  margin: 0;
  font-size: 0.9rem;
  line-height: 20px;
}
.notifications .notification_component .norilist li .data {
  font-size: 0.8rem;
  text-align: left;
  display: block;
}
.notifications .notification_component .norilist li::before {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  right: 0;
  background-color: #fedd00;
  border-radius: 50%;
  top: 15px;
}

.table_style {
  position: relative;
}

.tableoptions span {
  width: 35px;
  height: 36px;
  background-color: #3259a8;
  color: #fff;
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.tableoptions span svg {
  fill: #fff !important;
  width: 20px !important;
}
.tableoptions span:hover {
  opacity: 0.8;
}
.tableoptions .editicon {
  padding-top: 5px;
}
.tableoptions .historyicon {
  background-color: #fedd00;
}
.tableoptions .historyicon svg {
  fill: #000 !important;
}
.tableoptions .infoicon {
  background-color: #333;
}
.tableoptions .infoicon svg {
  fill: #fff !important;
}
.tableoptions .deleteicon {
  background-color: red;
}
.tableoptions .completeicon {
  background-color: green;
}

.history_dialog .MuiPaper-root {
  width: 700px;
}
.history_dialog h2.MuiTypography-root {
  background-color: #3259a8;
  color: #fff;
  font-family: "Baloo Bhaijaan 2", cursive !important;
}
.history_dialog .notfoundhistory {
  padding-top: 20px;
}
.history_dialog .css-qfso29-MuiTypography-root-MuiDialogContentText-root {
  font-family: "Baloo Bhaijaan 2", cursive !important;
}
.history_dialog ul {
  margin-bottom: 0;
}
.history_dialog li {
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  padding-bottom: 10px;
}
.history_dialog li p {
  font-size: 1rem;
}
.history_dialog li .date {
  display: block;
  text-align: left;
  margin-top: 5px;
}

.notification_component_visible {
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(0) !important;
}

.pending-status .MuiDataGrid-cellContent {
  background-color: #fedd00;
  padding: 7px 12px;
  border-radius: 5px;
}

.done-status .MuiDataGrid-cellContent {
  background-color: #3259a8;
  padding: 7px 12px;
  color: #fff;
  border-radius: 5px;
}

.main_content {
  background-color: #f4f4f4;
  position: relative;
  display: flex;
}
.main_content .btn_style {
  background-color: #3259a8;
  color: #fff;
  padding: 11px 56px;
  text-align: center;
  border: 0;
  border: 1px solid #3259a8;
  border-radius: 10px;
  font-family: "Baloo Bhaijaan 2", cursive;
  cursor: pointer;
}
.main_content .btn_style:hover {
  background-color: #fff;
  color: #3259a8;
}
.main_content .main_content_wrapper {
  padding-right: 64px;
  padding-bottom: 60px;
  width: 85%;
  padding-left: 100px;
}

.return_btn {
  position: absolute;
  left: 100px;
  top: 70px;
}
.return_btn a {
  display: flex;
  align-items: center;
  position: relative;
  color: #3259a8;
}
.return_btn a:after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: #3259a8;
}
.return_btn a i {
  margin-right: 10px;
}

.add_button {
  display: flex;
  margin-top: 64px;
}
.add_button a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 55px;
  background-color: #3259a8;
  margin-left: 50px;
  border-radius: 30px;
  font-size: 1.3rem;
  color: #fff;
  font-weight: 500;
  border: 1px solid #3259a8;
}
.add_button a:hover {
  background-color: #fff;
  color: #3259a8;
}

.side-nav {
  min-height: 100vh;
  height: auto;
  background-color: #3259a8;
  width: 15%;
  position: sticky;
  right: 0;
  top: 0;
}
.side-nav .cloe_nav {
  position: absolute;
  top: 10px;
  left: 20px;
  display: none;
}
.side-nav .cloe_nav i {
  font-size: 1.4rem;
  color: #fff;
}
.side-nav .person-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
}
.side-nav .person-info .card-img {
  width: 60px;
  height: 60px;
}
.side-nav .person-info .card-img img {
  border-radius: 50%;
}
.side-nav .person-info .card-body {
  margin-right: 10px;
}
.side-nav .person-info .card-body h5,
.side-nav .person-info .card-body p {
  margin: 0;
  color: #fff;
}
.side-nav .accordion_summery [class^=icon-],
.side-nav .accordion_summery [class*=" icon-"] {
  margin-right: 0;
}
.side-nav .side_links {
  display: flex;
  margin-top: 28px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
}
.side-nav .side_links ul {
  list-style: none;
  margin: 0;
  width: 100%;
}
.side-nav .side_links li {
  margin-bottom: 10px;
}
.side-nav .side_links li a {
  color: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  border-radius: 5px;
}
.side-nav .side_links li a svg {
  width: 30px;
}
.side-nav .side_links li a span {
  margin-right: 10px;
}
.side-nav .side_links li a:hover {
  background-color: #fff;
  color: #3259a8;
}
.side-nav .side_links li a:hover span:before {
  color: #3259a8;
}
.side-nav .side_links li a:hover svg {
  fill: #3259a8 !important;
}
.side-nav .side_links li a.active {
  background-color: #fff;
  color: #3259a8;
}
.side-nav .side_links li a.active span:before {
  color: #3259a8;
}
.side-nav .MuiAccordion-root {
  background-color: transparent;
  box-shadow: none;
}
.side-nav .MuiAccordion-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: #fff;
}
.side-nav .MuiAccordion-root span {
  margin-right: 20px;
  font-family: "Baloo Bhaijaan 2", cursive;
  color: #fff;
}
.side-nav .MuiTypography-root {
  font-family: "Baloo Bhaijaan 2", cursive;
}
.side-nav .css-15v22id-MuiAccordionDetails-root {
  padding-top: 0;
  padding-bottom: 0;
}

.customerSelect {
  display: block;
  width: 150px;
}
.customerSelect select {
  width: 100%;
  height: 40px;
}

#panel1a-header svg {
  fill: #fff;
}

.form_style {
  background-color: #fff;
  padding: 65px;
  width: 80%;
  max-width: 100%;
  border-radius: 10px;
  margin-top: 150px;
}
.form_style .form_header {
  margin-bottom: 35px;
}
.form_style h5 {
  font-size: 1.5rem;
  color: #3259a8;
}
.form_style p {
  font-size: 0.9rem;
  color: #000;
}
.form_style .form-group {
  margin-bottom: 20px;
}
.form_style .form-group .form-goup-label {
  display: flex;
  align-items: center;
}
.form_style .form-group .form-goup-label label {
  width: 150px;
  font-size: 1.2rem;
  color: #3259a8;
  font-weight: 500;
  flex: none;
}
.form_style .form-group .error {
  margin-right: 150px;
  margin-top: 5px;
  color: red;
}
.form_style .form-group .form-control {
  width: 60%;
  height: 50px;
  border-radius: 10px;
  background-color: #f3f3f3;
  border: 0;
  padding-right: 37px;
}
.form_style .form-group .form-control::-moz-placeholder {
  color: #9f9f9f;
  font-family: "Baloo Bhaijaan 2", cursive;
}
.form_style .form-group .form-control::placeholder {
  color: #9f9f9f;
  font-family: "Baloo Bhaijaan 2", cursive;
}
.form_style .form-group textarea.form-control {
  height: 124px;
  resize: none;
}
.form_style .form-group select.form-control {
  width: 90%;
  color: #9f9f9f;
}
.form_style .btn_submit {
  text-align: left;
  margin-top: 40px;
}
.form_style input[type=file] {
  line-height: 50px;
}

.formEditData select.form-control {
  color: #000 !important;
}

.Toastify__toast-body {
  font-family: "Baloo Bhaijaan 2", cursive;
  text-align: right !important;
}
.Toastify__toast-body div {
  font-family: "Baloo Bhaijaan 2", cursive;
}

a,
button {
  font-family: "Baloo Bhaijaan 2", cursive !important;
  cursor: pointer;
}

.list-unstyled {
  list-style-type: none;
  margin: 0;
}

.options_buttons a,
.options_buttons button {
  width: 100%;
  height: 123px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #3259a8;
  border-radius: 10px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
  font-size: 1.2rem;
}
.options_buttons a.active,
.options_buttons button.active {
  background-color: #3259a8;
}
.options_buttons a:hover,
.options_buttons button:hover {
  background-color: #3259a8;
  color: #fff;
}

.table_style {
  background-color: #fff;
  padding: 37px 30px;
  margin-top: 64px;
  border-radius: 10px;
  width: 100%;
}
.table_style h5 {
  color: #3259a8;
  font-size: 1.9rem;
  font-weight: bold;
}
.table_style .btn_options {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.table_style .btn_options .btn_style {
  margin-right: 10px;
}
.table_style .btn_options .btn_style:first-child {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
  color: #3259a8;
}
.table_style .btn_options .btn_style:first-child:hover {
  background-color: #fff;
}
.table_style .css-gl260s-MuiDataGrid-columnHeadersInner {
  color: #000;
  width: -moz-max-content;
  width: max-content;
}
.table_style .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root[data-testid=DeleteIcon] {
  fill: red;
}
.table_style .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root[data-testid=EditNoteOutlinedIcon] {
  fill: #3259a8;
}
.table_style .form-group {
  margin-bottom: 20px;
}
.table_style .form-group label {
  width: 150px;
  font-size: 1.2rem;
  color: #3259a8;
  font-weight: 500;
  flex: none;
  margin-bottom: 10px;
}
.table_style .form-group .error {
  margin-right: 150px;
  margin-top: 5px;
  color: red;
}
.table_style .form-group .form-control {
  width: 60%;
  height: 50px;
  border-radius: 10px;
  background-color: #f3f3f3;
  border: 0;
  padding-right: 37px;
}
.table_style .form-group .form-control::-moz-placeholder {
  color: #9f9f9f;
  font-family: "Baloo Bhaijaan 2", cursive;
}
.table_style .form-group .form-control::placeholder {
  color: #9f9f9f;
  font-family: "Baloo Bhaijaan 2", cursive;
}
.table_style .form-group textarea.form-control {
  height: 124px;
  resize: none;
}
.table_style .form-group select.form-control {
  width: 90%;
  color: #9f9f9f;
}
.table_style .btn_submit {
  margin-top: 15px;
  text-align: left;
}
.table_style .titlefor_table {
  margin-bottom: 0 !important;
  margin-top: 30px !important;
}

.map_draewr {
  margin-top: 50px;
  margin-bottom: 50px;
}

.manage_employee .table_show h5 {
  margin-bottom: 30px;
}
.manage_employee .showall {
  position: relative;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  text-align: left;
  margin-bottom: 40px;
  color: #3259a8;
  background-color: transparent;
  border: 0;
  font-size: 1rem;
}
.manage_employee .showall::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: #3259a8;
}

.paginaitonMod {
  direction: ltr;
  margin-top: 10px;
}

.table_filter {
  padding: 10px;
  padding-bottom: 30px;
}
.table_filter h5.head {
  font-size: 1.4rem;
  color: #3259a8;
  margin: 0;
  margin-bottom: 10px;
}
.table_filter .form_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.table_filter .form_wrapper .form_group {
  flex: 1 1 25%;
  margin-bottom: 15px;
}
.table_filter .form_wrapper label {
  font-size: 0.9rem;
  margin-bottom: 10px;
  display: block;
}
.table_filter .form_control {
  width: 90%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.table_filter .btn_style {
  margin-top: 10px;
}

.filter form {
  display: flex;
  align-items: center;
}
.filter .form-group {
  width: 25%;
  margin-bottom: 0;
}
.filter .w-100 {
  width: 50%;
}
.filter .btn_submit {
  width: 100px;
  margin-top: 0;
}
.filter .btn_submit .btn {
  background-color: #3259a8;
  border: 0;
  width: 100%;
  border: 1px solid #3259a8;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
}

input[type=date],
input[type=number],
input[type=email] {
  text-align: right;
}

.inputFile input {
  margin-left: 10px;
}
.inputFile svg {
  fill: #4fa1ff;
}

.download_file .btn_style {
  display: block;
  margin-right: auto;
}

.markatername {
  font-size: 1.6rem;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.empty_complete {
  display: flex;
  align-items: center;
  justify-content: end;
}

.agent_dashboard {
  padding: 60px 0;
  padding-top: 0;
  background-color: #f1f1f1;
  min-height: 100vh;
  height: auto;
}
.agent_dashboard .top_header {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.agent_dashboard .top_header .btn_style {
  background-color: red;
  color: #fff;
  padding: 12px 25px;
}
.agent_dashboard .top_header h5 {
  font-size: 1.3rem;
  margin: 0;
}
.agent_dashboard .top_header p {
  font-size: 1.3rem;
  margin: 0;
}
.agent_dashboard .top_header .agent_link {
  word-wrap: break-word;
}
.agent_dashboard hr {
  background-color: #cbcbcb;
}
.agent_dashboard .top_header_balance {
  padding: 30px;
}
.agent_dashboard .balance_table {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.agent_dashboard .balance_table h5 {
  margin: 0;
  font-size: 1rem;
}
.agent_dashboard .agentDash_navbar {
  margin-bottom: 60px;
  padding: 15px 0;
  color: #fff;
}
.agent_dashboard .agentDash_navbar .btn {
  color: #000;
  padding: 10px 30px;
}
.agent_dashboard .agentDash_navbar .btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

@media (max-width: 600px) {
  .agent_dashboard .top_header {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .agent_dashboard .top_header h5 {
    margin-bottom: 15px;
  }
}
.edit_form input {
  padding: 20px;
  width: 100%;
  border: 1px solid #ddd;
}
.edit_form .btn {
  background-color: #0f4072;
  border: 1px solid #0f4072;
  color: #fff;
  width: 100%;
}
.edit_form .btn:hover {
  color: #0f4072;
  background-color: #fff;
}

.MuiDataGrid-columnHeaderTitle {
  font-family: "Baloo Bhaijaan 2", cursive;
}

.css-wop1k0-MuiDataGrid-footerContainer {
  direction: ltr;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root[data-testid=LogoutOutlinedIcon] {
  margin-right: -10px;
  width: auto !important;
}

.side-nav .side_links li.signout_btn a {
  background-color: #ff2147;
  border-radius: 5px;
  cursor: pointer;
}
.side-nav .side_links li.signout_btn a:hover {
  background-color: rgba(255, 33, 70, 0.7764705882) !important;
  color: #fff !important;
}
.side-nav .side_links li.signout_btn a:hover svg {
  fill: #fff !important;
}

.statics_wrapper {
  padding: 60px 0;
}
.statics_wrapper .statics_header {
  font-size: 2rem;
  color: #3259a8;
  margin-bottom: 20px;
}

.box_style_bg {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding-bottom: 25px;
  min-height: 500px;
}
.box_style_bg .box_header {
  background-color: #c4c3c1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 16px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}
.box_style_bg .day_box {
  background-color: #3259a8;
}

.statics_card {
  background-color: #fff;
  border-radius: 10px;
  padding: 36px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  text-align: center;
  margin-bottom: 20px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.statics_card h5 {
  color: #3259a8;
  font-size: 1.2rem;
  font-weight: 500;
}
.statics_card .number {
  font-size: 2rem;
  color: #3259a8;
  font-weight: 700;
}

.login_page {
  min-height: 100vh;
  height: auto;
  background-color: #3259a8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 50px 0;
}
.login_page .wavelogin {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.login_page .login_form {
  background-color: #fff;
  width: 35%;
  margin: auto;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
}
.login_page .login_form .logo {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}
.login_page .login_form .form-group {
  margin-bottom: 20px;
}
.login_page .login_form .form-group label {
  margin-bottom: 5px;
  color: #3259a8;
  font-size: 1.2rem;
  font-weight: 500;
  display: block;
}
.login_page .login_form .form-group .form-control {
  height: 50px;
  border-radius: 5px;
  background-color: #f7f7f7;
  border: 0;
  width: 100%;
  padding: 15px;
}
.login_page .login_form .form-group .error {
  color: red;
  font-size: 0.9rem;
}
.login_page .btn_submit .btn {
  background-color: #3259a8;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  text-align: center;
  font-family: "Baloo Bhaijaan 2", cursive;
  border-radius: 5px;
  font-size: 1.1rem;
  border: 1px solid #3259a8;
  transition: all 0.3s ease-in-out;
  display: block;
  width: 100%;
  cursor: pointer;
}
.login_page .btn_submit .btn:hover {
  background-color: #fff;
  color: #3259a8;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  direction: ltr !important;
  padding: 0 10px !important;
}

.MuiTablePagination-actions {
  direction: rtl;
}

.css-1sazv7p-MuiStack-root > :not(style):not(style) {
  direction: ltr !important;
  margin-top: 20px !important;
}

.react-confirm-alert-body {
  text-align: right !important;
  font-family: inherit !important;
}

.react-confirm-alert-button-group {
  justify-content: flex-end !important;
  font-family: inherit !important;
}
.react-confirm-alert-button-group button {
  font-family: inherit !important;
  background-color: #3259a8 !important;
}

.css-128fb87-MuiDataGrid-toolbarContainer {
  padding: 15px 5px !important;
  font-family: "Baloo Bhaijaan 2", cursive !important;
}

.css-128fb87-MuiDataGrid-toolbarContainer {
  font-family: "Baloo Bhaijaan 2", cursive !important;
}

.menuIcon {
  width: 50px;
  height: 50px;
  background-color: #3259a8;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.menuIcon svg {
  fill: #fff;
  width: 40px;
}

.backdropmenu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  opacity: 0;
}

.backdropmenu_active {
  opacity: 1;
  z-index: 3;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root svg {
  margin-left: 5px;
  margin-top: -2px;
}

.reports_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding: 36px;
}
.reports_list li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reports_list li a {
  border: 0;
  background-color: #3259a8;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #3259a8;
  padding: 8px 30px;
  font-family: "Baloo Bhaijaan 2", cursive;
  display: flex;
  align-items: center;
  min-width: 140px;
  max-width: 140px;
  justify-content: center;
}
.reports_list li a span {
  margin-left: 5px;
}
.reports_list li a svg {
  width: 18px;
}
.reports_list li a:hover {
  background-color: #fff;
  color: #3259a8;
}

.list_reports_bg .box_header {
  background-color: #fedd00;
  color: #3259a8;
  margin-bottom: 0;
}
.list_reports_bg .reports_list {
  padding: 0;
}
.list_reports_bg li {
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  padding: 20px 30px;
}
.list_reports_bg li:first-child {
  background-color: #3259a8;
  color: #fff;
}
.list_reports_bg .reports_list_team li:first-child {
  background-color: transparent !important;
  color: #000 !important;
}

@media print {
  body {
    font-size: 12px;
    color: black;
  }
  /* Additional print styles */
  /* For example, you might hide certain elements not relevant for printing */
  .header,
  .sidebar {
    display: none;
  }
  /* Adjust spacing and layout for printing */
  .content {
    margin: 0;
    padding: 0;
  }
  .MuiDataGrid-toolbarContainer.css-128fb87-MuiDataGrid-toolbarContainer,
  .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor.css-wop1k0-MuiDataGrid-footerContainer {
    display: none;
  }
  .css-1llehkv-MuiGrid2-root {
    width: 100%;
  }
}
.dialog-info {
  padding-top: 20px;
}

.tableoptions .change-pass {
  background-color: #fedd00 !important;
}
.tableoptions .change-pass svg {
  fill: #000 !important;
}
.tableoptions .number_of_items {
  background-color: transparent;
  color: #000;
  border: 1px solid #eee;
  display: flex !important;
  height: 35px;
  width: 40px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
}
.tableoptions .number_of_items:hover {
  background-color: #eee;
}

.gm-style .gm-style-iw-c {
  padding-right: 12px !important;
}

.map_dialog .MuiPaper-root {
  width: 900px;
  max-width: 100%;
}

.callDone {
  background-color: green;
  padding: 7px;
  border-radius: 5px;
  color: #fff;
}

.callNotDone {
  background-color: #444;
  padding: 7px;
  border-radius: 5px;
  color: #fff;
}

.make_it_called {
  background-color: #3259a8;
  color: #fff;
  border: 0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 0;
}
.make_it_called svg {
  height: 15px;
  margin-top: 5px;
}

.custom_filter form {
  flex-wrap: wrap;
  align-items: center;
}
.custom_filter .form-group {
  width: 50% !important;
  margin-bottom: 10px;
}
.custom_filter .form-group .form-control {
  width: 90% !important;
}

.empty_courses {
  text-align: left;
}

@media (max-width: 1300px) {
  .side-nav .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type span,
  .side-nav .side_links li a span {
    margin-right: 5px;
  }
  .side-nav {
    width: 20%;
  }
  .main_content .main_content_wrapper {
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .main_content .main_content_wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .login_page .login_form {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .side-nav {
    width: -moz-max-content;
    width: max-content;
    position: fixed;
    top: 0;
    right: -100%;
    transition: all 0.3s ease-in-out;
    z-index: 7;
    overflow-y: auto;
    height: 100vh;
  }
  .side-nav .cloe_nav {
    display: block;
  }
  .add_button {
    flex-wrap: wrap;
  }
  .add_button a {
    text-align: center;
    font-size: 1rem;
    padding: 15px;
    height: auto;
    width: 40%;
    margin-bottom: 10px;
  }
  .options_buttons ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .options_buttons ul li {
    width: 40%;
    margin-left: 20px;
  }
  .options_buttons a,
  .options_buttons button {
    height: auto;
    padding: 15px;
  }
  .menuIcon {
    display: flex;
  }
  .openmenu {
    right: 0;
  }
  .main_content .main_content_wrapper {
    width: 100%;
  }
  .login_page .login_form {
    width: 80%;
  }
}
@media (max-width: 700px) {
  .add_button a {
    margin-left: 20px;
  }
  .filter form {
    flex-wrap: wrap;
  }
  .filter .form-group {
    width: 100%;
    margin-bottom: 10px;
  }
  .form_style {
    width: 100%;
    padding: 35px;
  }
  .form_style .form-group .form-goup-label {
    align-items: flex-start;
    flex-direction: column;
  }
  .form_style .form-group .form-control,
  .form_style .form-group select.form-control {
    width: 100%;
  }
  .login_page .login_form {
    width: 100%;
  }
  .notification_component {
    max-width: 250px;
  }
}
@media (max-width: 400px) {
  .list_reports_bg .reports_list_team li:first-child {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: -15px !important;
}

.quill {
  height: 300px;
}

.ql-editor {
  text-align: right !important;
  direction: rtl;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-right: 1em !important;
  margin-left: 0 !important;
}/*# sourceMappingURL=style.css.map */